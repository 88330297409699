//export const base_url = "https://nodepune-aibotdevotionalsbooks.mobiloitte.io/api/v1/";
// export const base_url = "http://172.16.2.14:3043/api/v1/";

export const base_url = "https://pyaibot-writesdevotionals.mobiloitte.io/";

export const web_socket = "ws://nodepune-aibotdevotionalsbooks.mobiloitte.io:3043";
// export const web_socket = "ws://172.16.2.14:3043";


export const searchVerseEndPoint = "bibalapi/";
//export const searchVerseEndPoint = "verse/searchVerse";
export const getChatEndPoint = "bibalapi/";