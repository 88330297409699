import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import Axios from "axios";
import { RxCross1 } from "react-icons/rx";
import SearchIcon from '@material-ui/icons/Search';

import {
  base_url,
  searchVerseEndPoint,
  getChatEndPoint,
} from "../../APIConfig/endpoints";
const deviceHeight = window.screen.height;
const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    borderRadius: "7px",
    border: "1px solid #000",
    maxWidth: "490px",
height:"fit-content"
  },
  mainbox: {

    background: "#1E1E1E",

    padding: deviceHeight / 40
  },
  bible: {
    // paddingTop: "33px",
  },
  category: {
    // padding: "9px 125px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "22.652px",
    lineHeight: "29px",
    color: "#1E1E1E",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "10px",
    "@media only screen and (max-width:500px)": {
      fontSize: "15px",
    },
    "@media only screen and (max-width:337px)": {
      fontSize: "13px",
    },
  },
  btn: {
    gap: "30px",
    padding: "10px 0",
  },
  button: {
    display: "flex",
    justifycontent: "center",
    gap: "30px",
  },
  malebutton: {
    background: "#1E1E1E",
    borderRadius: "5px",
    color: "#FFFFFF",
    textTransform: "capitalize",
  },
  malebutton1: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "none",
    fontSize: "18px",
    lineHeight: "31px",
    color: "#000",
    border: "1px solid #1E1E1E",
  },
  femalebutton: {
    background: "#1E1E1E",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "none",
    fontSize: "18px",
    lineHeight: "31px",
    textTransform: "capitalize",
    borderRadius: "5px",
    color: "#ffffff",
  },
  femalebutton1: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "none",
    fontSize: "18px",
    lineHeight: "31px",
    color: "#000",
    border: "1px solid #1E1E1E",
    borderRadius: "5px",
  },
  otherbutton: {
    background: "#1E1E1E",
    textTransform: "capitalize",
    borderRadius: "5px",
    color: "#FFFFFF",
  },
  otherbutton1: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "none",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#000",
    border: "1px solid #1E1E1E",
  },
  maindiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",

    "@media only screen and (max-width:500px)": {
      padding: "0",
    },
  },
  textfield: {
    marginTop: "27px",
    border: "1px solid #1E1E1E",
  },
  forminputDate: {
    padding: "0px 10px 0 10px",

  },
  search: {
    color: "#FFFFFF !important",
    maxWidth: "100%",
    height: "54px",
    marginTop: "8px",
    background:"transparent",
    "& .MuiOutlinedInput-root": {
      height: "54px",
      border: "2px solid #ffffff",
      paddingRight: "0 !important",
      borderRadius: "18px",
      color:"white !important",
      "&:hover": {
        height: "54px",
        border: "2px solid #ffffff",
        paddingRight: "0 !important",
      },
      "&.Mui-focused": {
        border: "2px solid #ffffff",
        paddingRight: "0 !important",
        outline: "none", // remove the focus outline
      },
      "&.Mui-focused fieldset": {
        outline: "none !important",
      },
    },
  },
  cardcontent: {
    padding: "0px 0 20px 0 !important",
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "none !important",
      display: "flex",
      maxWidth: "100%",
    },
  },
  avtartext: {
    paddingTop: "12px",
    maxWidth: "100%",
    display: "flex",
  },
  question: {
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF",
  },
  imgarrow: {
    "@media(max-width:541px)": {
      width: "80px",
      maxWidth: "100%",
      display: "flex",
    },
  },
  container: {
    display: "flex",
    maxLength: "100%",
  },
  selfMessage: {
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF;",
    padding: theme.spacing(1),

    alignSelf: "flex-end",
    marginBottom: theme.spacing(1),
    display: "flex",
    gap: "10px",

  },
  responseMessage: {
    backgroundColor: "#ffffff",
    padding: theme.spacing(1),
    borderRadius: "10px",
    alignSelf: "flex-start",
    marginBottom: theme.spacing(1),
    display: "flex",
    gap: "10px",

  },
  chatWindow: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  chatMessagesContainer: {
    height: "100%",
    overflow: "auto",
       height: 417 ,
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome, Safari, and Opera */,
    },
  },
  chatMessages: {},
  clearDiv: {
    cursor: "pointer",
    background: "#000",
    padding: "16px",
    borderBottomRightRadius: "18px",
    borderTopRightRadius: "18px",
    "& svg ": {
      color: "white",
    },
  },
  loaderBlink: {
    paddingLeft: "10px",
    "&::after": {
      content: '""',
      width: "5px",
      height: "29px",
      background: "#000",
      display: "inline-block",
      animation: "$cursor-blink 1.5s steps(2) infinite",
    },
  },
  "@keyframes cursor-blink": {
    "0%": {
      opacity: 0,
    },
  },
  imgCustom: {
    height: "33px",
    width: "36px"
  }
}));

const Widget = () => {
  const classes = useStyles();
  const [genderbtn, setGenderBtn] = useState("female");
  const [searchdata, setSearchData] = useState("");
  const [myMessage, setMyMessage] = useState("");
  const [mesg, setMesg] = useState("");
  const bottomRef = useRef(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [disable, showDisable] = useState(true)
  const searchDetails = async () => {
    showDisable(false)
    setSearchData("");
    setMyMessage(searchdata);
    try {
      const res = await Axios(`${base_url}${searchVerseEndPoint}`, {
        method: "POST",
        data: {
          // gender: genderbtn,
          inputtext: searchdata,
        },
      });
      if (res.data.status === 200) {
        showDisable(true)
        setMesg(res.data.assistance);
        receiveResponse(res.data.assistance);
      } else {
      }
    } catch (error) {
      showDisable(true)
      console.log(error, "error");
    }
  };

  const sendMessage = (message) => {
    const newMessage = { message: message, response: { quote: "", explantion: "" } };
    setChatMessages([...chatMessages, newMessage]);
  };

  const receiveResponse = (response) => {
    const lastMessageIndex = chatMessages.length - 1;
    const lastMessage = chatMessages[lastMessageIndex];

    if (lastMessage && !lastMessage.response) {
      const updatedLastMessage = { ...lastMessage, response: response };
      const updatedChatMessages = [...chatMessages];
      updatedChatMessages[lastMessageIndex] = updatedLastMessage;
      setChatMessages(updatedChatMessages);
    } else {
      const newMessage = { message: searchdata, response: response };
      setChatMessages([...chatMessages, newMessage]);
    }
  };
  console.log(chatMessages, "chatMessages")
  const handleKeyDown = (event) => {
    if(searchdata!=""){
      if (event.key === "Enter") {
        sendMessage(searchdata);
        searchDetails();
      }
    }

  };


  const [expCurrIndex, setExpCurrIndex] = useState(0);
  
  function ChatMessage(props) {
    const { message, quote, explanation, response } = props;
    const quoteRef = useRef(null);
    const explanationRef = useRef(null);
  
    useEffect(() => {
      if (quoteRef.current) {
        quoteRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, [quote, explanation]);
  
    useEffect(() => {
      if (explanationRef.current) {
        explanationRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, [explanation]);
  
    useEffect(() => {
      const quoteLength = quote ? quote.length : 0;
      const explanationLength = explanation ? explanation.length : 0;
  
      if (quoteLength > 0 && currentIndex < quoteLength) {
        const quoteTimer = setTimeout(() => {
          setCurrentIndex(currentIndex + 1);
        }, 50);
        return () => clearTimeout(quoteTimer);
      } else if (explanationLength > 0 && expCurrIndex < explanationLength) {
        const explanationTimer = setTimeout(() => {
          setExpCurrIndex(expCurrIndex + 1);
        }, 50);
        return () => clearTimeout(explanationTimer);
      }
    }, [currentIndex, quote, expCurrIndex, explanation]);
  
    return (
      <div>
        {message && (
          <div className={classes.selfMessage}>
            <img className={classes.imgCustom} src="/images/Profile.svg" />
            <Typography style={{ textAlign: 'justify' }} variant="body1">
              {message}
            </Typography>
          </div>
        )}
        {!quote && <p className={classes.loaderBlink}></p>}
        {quote && (
          <div className={classes.responseMessage}>
            <img className={classes.imgCustom} src="/images/cross.svg" />
            <div>
              <Typography style={{ textAlign: 'justify' }} variant="body1">
                {quote && quote.slice(0, currentIndex)}
              </Typography>
              <br />
              <Typography
                ref={explanationRef}
                style={{ textAlign: 'justify' }}
                variant="body1"
              >
                {explanation && explanation.slice(0, expCurrIndex)}
              </Typography>
            </div>
          </div>
        )}
        <div ref={quoteRef}></div>
      </div>
    );
  }
  
 
  return (
    <>
      <div className={classes.maindiv}>
        <Card className={classes.card}>
          <Box
            className={classes.mainbox}
            
          >
            {/* <Typography className={classes.bible}>
              <img
                className={classes.img}
                src="/images/bible.svg"
                height={50}
              />
            </Typography> */}
                    <div className={classes.forminputDate}>
                <TextField
                  // style={{ backgroundColor: "#fff" }}
                  placeholder="Search devotions"
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                  disabled={disable == false}
                  inputProps={{ maxLength: 256 }}
                  value={searchdata}
                  onChange={(e) => setSearchData(e.target.value)}
                  className={classes.search}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    className: classes.TextBox,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box style={{ paddingRight:"4px"}}>
                          <IconButton edge="end" disabled={searchdata==""}>
                            <SearchIcon
                            style={{color:"white"}}
                              onClick={() => {
                                searchDetails();
                                sendMessage(searchdata);
                              }}
                            />
                          </IconButton>
                          {/* <Box
                            className={classes.clearDiv}
                            onClick={() => setChatMessages([])}
                          >
                            <RxCross1 />
                          </Box> */}
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
          </Box>
          <CardContent className={classes.cardcontent}>
            {/* <Typography className={classes.category}>
              <img className={classes.imgarrow} src="/images/arrow1.svg" />{" "}
              Select Your Gender{" "}
              <img className={classes.imgarrow} src="/images/arrow2.svg" />
            </Typography>
            <div
              className={classes.btn}
              style={{ display: "flex", gap: "16px", justifyContent: "center" }}
            >
              <button
                className={
                  genderbtn === "male"
                    ? classes.femalebutton
                    : classes.femalebutton1
                }
                onClick={() => setGenderBtn("male")}
              >
                Male
              </button>

              <button
                className={
                  genderbtn === "female"
                    ? classes.femalebutton
                    : classes.femalebutton1
                }
                onClick={() => setGenderBtn("female")}
              >
                Female
              </button>
              <button
                className={
                  genderbtn === "OTHER"
                    ? classes.femalebutton
                    : classes.femalebutton1
                }
                onClick={() => setGenderBtn("OTHER")}
              >
                Child
              </button>
            </div> */}
            <Grid container direction="column" className={classes.chatWindow}>
              <div
                
                className={classes.chatMessagesContainer}
              >
                <Box className={classes.chatMessages}>
                  {chatMessages.map((message, index) => (

                    <ChatMessage
                      key={index}
                      message={message.message}
                      quote={message.response.quote}
                      explanation={message.response.explanation}
                      response={message.response}
                    />
                  ))}
                </Box>
              </div>
      
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Widget;

